/**
 * Calculate the average weighted
 * @function
 * @param {array} valuesWithWeight Array of objects that contain the value and weight keys
 * @returns {number} Averate Weighted
 */
const averageWeighted = (valuesWithWeight) => {

    if (valuesWithWeight) {
        let valueTimesWeight = 0
        let weightSum = 0

        valuesWithWeight.forEach(valueWithWeight => {
            const { value, weight } = valueWithWeight
            valueTimesWeight = MathOperation.sum(valueTimesWeight, MathOperation.multiply(value, weight))
            weightSum = MathOperation.sum(weightSum, weight)
        })

        return MathOperation.divide(valueTimesWeight, weightSum)
    } else
        return 0
}

const parseValueToTwoDecimalsWithoutRound = value => {

    if (value) {
        value = (+value).toFixed(2)
        const valueSplitted = value.split('.')
        if (valueSplitted.length > 0) {
            const strValue = valueSplitted[0] + (valueSplitted[1] ? '.' + valueSplitted[1].substring(0, 2) : '')
            return new Intl.NumberFormat('en', { minimumFractionDigits: 2 }).format(+strValue)
        } else
            return value
    } else
        return value
}

/**
 * This method parses a string to the correspondent float value
 * @param {string} value 
 * @returns {number | null | NaN}
 */
const stringToFloat = value => {
    let valueParsed = value
    if (value || typeof number === 'number') valueParsed = parseFloat(valueParsed.toString().replace(/,/g, ''))
    return valueParsed
}

/**
 * Method used to set the number of digits after the decimal point.
 * @param {string | float} number Number to be changed
 * @param {objetct | null} config Config options
 * @param {number | null} config.minimumFractionDigits
 * @param {number | null} config.maximumFractionDigits
 * @param {boolean | null} config.useGrouping Whether to use grouping separators, such as thousands separators or thousand/lakh/crore separators
 * @param {string | null} config.locale
 * @returns {string}
 */
const formatNumber = (number, config) => {

    let defaultConfig = { minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: false, locale: 'en-US' }
    if (config) defaultConfig = { ...defaultConfig, ...config }

    const { minimumFractionDigits, maximumFractionDigits, useGrouping, locale } = defaultConfig

    if (number || typeof number === 'number') {
        number = stringToFloat(number)
        return new Intl.NumberFormat(locale, {
            minimumFractionDigits,
            maximumFractionDigits,
            useGrouping
        }).format(number);
    } else return number
}
/**
 * All the math operations should be done through this class
 */
class MathOperation {

    /**
    * @param {number | string} numberOne 
    * @param {number | string} numberTwo 
    * @returns {number} Sum Result
    */
    static sum(numberOne, numberTwo) {
        const { firstNumber, secondNumber } = this.parseParameters(numberOne, numberTwo)
        return +((firstNumber + secondNumber).toFixed(2))
    }

    /**
     * @param {number | string} numberOne 
     * @param {number | string} numberTwo 
     * @returns {number} Difference of the two parameters
     */
    static minus(numberOne, numberTwo) {
        const { firstNumber, secondNumber } = this.parseParameters(numberOne, numberTwo)
        return +((firstNumber - secondNumber).toFixed(2))
    }

    /**
     * @param {number | string} numerator 
     * @param {number | string} denominator 
     * @param {number | null} fractionDigits 
     * @returns {number} Division result
     */
    static divide(numerator, denominator, fractionDigits = 2) {
        const { firstNumber, secondNumber } = this.parseParameters(numerator, denominator, fractionDigits)
        return +((firstNumber / secondNumber).toFixed(fractionDigits))
    }

    /**
    * @param {number | string} numberOne 
    * @param {number | string} numberTwo 
    * @param {number | null} fractionDigits 
    * @returns {number} Multiplication result
    */
    static multiply(numberOne, numberTwo, fractionDigits = 2) {
        const { firstNumber, secondNumber } = this.parseParameters(numberOne, numberTwo, fractionDigits)
        return +((firstNumber * secondNumber).toFixed(fractionDigits))
    }

    static parseParameters(firstNumber, secondNumber, fractionDigits = 2) {

        const firstNumberFloat = +((stringToFloat(firstNumber) || 0).toFixed(fractionDigits))
        const secondNumberFloat = +((stringToFloat(secondNumber) || 0).toFixed(fractionDigits))
        return { firstNumber: firstNumberFloat, secondNumber: secondNumberFloat }
    }

}


const roundAmount = (value, roundingPattern) => Math.round(parseFloat(value)/roundingPattern)*roundingPattern;

const validateMinMax = (newValue, min, max) => {
    const numericValue = parseFloat(newValue);
    if (isNaN(numericValue)) {
      return false;
    }
    
    if (typeof min === 'number' && typeof max === 'number') {
      return numericValue >= min && numericValue <= max;
    } else if (typeof min === 'number') {
      return numericValue >= min;
    } else if (typeof max === 'number') {
      return numericValue <= max;
    }
    
    return true; 
};

const isInteger = (value) => value && Number.isInteger(+value);
export { MathOperation, averageWeighted, parseValueToTwoDecimalsWithoutRound, stringToFloat, formatNumber, roundAmount, isInteger,validateMinMax }