import React from 'react';

const ExchangeIcon = ({ color = '#1160F7' }) => {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
      <path
        d="M14.1667 3.93207V17.2654M14.1667 17.2654L10.8333 13.9321M14.1667 17.2654L17.5 13.9321M5.83333 17.2654V3.93207M5.83333 3.93207L2.5 7.2654M5.83333 3.93207L9.16667 7.2654"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExchangeIcon;
