import { ADD_ADDITIONAL_FIELDS_CHILD } from '../actionTypes';
import {
  beneficiaryPaymentMethodsQuery,
  beneficiaryPaymentMethodListQuery,
  beneficiaryPaymentDetailsQuery,
  beneficiaryFieldValuesQuery,
  updateBeneficiaryPaymentMethodsMutation,
  validateBankReferenceNumberQuery,
  validateBankReferenceNumberBankCodeQueryV2,
  validateBankReferenceNumberInternalQueryV2,
  validateBeneficiaryFieldValueQuery,
  beneficiaryPaymentV3Query
} from './graphql.graphql';
import { apiMainQuery } from '../../../settings/apollo/api/main/query';
import { apiMainMutation } from '../../../settings/apollo/api/main/mutation';

/**
 * @function getBeneficiaryPaymentMethods
 * @param country_to_id - Coutry ID which it will be used to fetch data for payments method.
 * @param [receivingType=default] it is optinal in case of filter by payment type.
 * payments types are: delivery, pickup or bank_deposit
 */

export const getBeneficiaryPaymentMethods = (country_id, method, ben_type, currency_id) => {
  if (!method) return apiMainQuery(beneficiaryPaymentMethodsQuery, { country_id, currency_id })
  else return apiMainQuery(beneficiaryPaymentDetailsQuery, { country_id, currency_id, method, ben_type })
}

export const updateBeneficiaryPaymentMethods = (field, value, id, ben_id) => apiMainMutation(
  updateBeneficiaryPaymentMethodsMutation,
  {
    field,
    value,
    id,
    ben_id,
  },
);

export const validateBankReferenceNumber = (brc, brc_type, regex) => apiMainQuery(
  validateBankReferenceNumberQuery,
  { brc, brc_type, regex },
);

export const validateBankReferenceNumberBankCodeV2 = (brc, brc_type, regex) => apiMainQuery(
  validateBankReferenceNumberBankCodeQueryV2,
  { brc, brc_type, regex },
)


export const validateBankReferenceNumberInternalV2 = (brc, brc_type, bank_id) => apiMainQuery(
  validateBankReferenceNumberInternalQueryV2,
  { brc, brc_type, bank_id }
)

export const validateBeneficiaryFieldValue = (field_id, field_value, payer_id) => apiMainQuery(
  validateBeneficiaryFieldValueQuery,
  { field_id, field_value, payer_id }
)

export const getBeneficiaryFieldValues = ({
  field_id,
  field_value,
  country_id,
  currency_id,
  method,
  ben_type,
}) => {
  return apiMainQuery(beneficiaryFieldValuesQuery, {
    field_id,
    field_value,
    country_id,
    currency_id,
    method,
    ben_type,
  });
};

export const addAdditionalFieldChild = child => ({
  type: ADD_ADDITIONAL_FIELDS_CHILD,
  payload: child,
});

export const beneficiaryPaymentMethodList = (country_id, currency_id) => {
  return apiMainQuery(beneficiaryPaymentMethodListQuery, { country_id, currency_id });
}

export const beneficiaryPaymentMethodsV3 = (country_id, method, ben_type, currency_id, should_send_contact_details) => {
  return apiMainQuery(beneficiaryPaymentV3Query, { country_id, currency_id, method, ben_type, should_send_contact_details })
}