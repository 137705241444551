import { DocumentNode } from "graphql";
import browserLogger from '../../../../settings/browser-logger';
import { FetchPolicy } from "@apollo/client";
import client from "./client";
import { store } from '../../../../redux/store';
import * as Sentry from "@sentry/nextjs";

export interface QueryResult {
    success: boolean;
    errorMessage: string | null;
    data: any;
}

const QUERY_SESSION_URI = `${process.env.REACT_APP_API}/v2/customers/sessions/queries`;

export const apiSessionQuery = (
    query: DocumentNode,
    variables: Record<string, any> = {},
    fetchPolicy: FetchPolicy = 'network-only'
): Promise<QueryResult> => {
    return new Promise(resolve => {
        let objToBeReturned = { success: true, errorMessage: null, data: null };
        client
            .query({
                variables,
                query,
                fetchPolicy,
                context: { uri: QUERY_SESSION_URI }
            })
            .then(({ data }) => {
                objToBeReturned.data = data;
                resolve(objToBeReturned);
            })
            .catch(err => {
                const { customer } = store.getState();
                browserLogger.error(
                    err.message,
                    {
                        error: err,
                        function: 'graphqlSessionQuery',
                        cus_unique_id: customer.cus_unique_id,
                    },
                );
                Sentry.captureException(err, (scope) => {
                    scope.clear();
                    scope.setContext("additionalData", {
                      cus_unique_id: customer?.cus_unique_id,
                      query: query && query?.definitions && query?.definitions?.length > 0 && query?.definitions[0]['name'] && query?.definitions[0]['name']?.value
                    });
                    return scope;
                });
                objToBeReturned = { ...objToBeReturned, success: false, errorMessage: err.message };
                resolve(objToBeReturned);
            });
    });
};