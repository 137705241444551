import {
  SET_ORIGINAL_COUNTRY_ID,
  SET_CONVERTED_COUNTRY_ID,
  SET_EXCHANGE_AMOUNT,
  SET_CONVERTED_AMOUNT,
  SET_EXCHANGE_RATE,
  SET_PROMO_RATE,
  SET_SPECIAL_RATE,
  SET_ORIGINAL_CURRENCY_EFT_INFO_ID,
  SET_CONVERTED_CURRENCY_EFT_INFO_ID,
  SET_CONVERTED_CURRENCY_FLINKS_LOGIN_ID,
  SET_ORIGINAL_CURRENCY_FLINKS_LOGIN_ID,
  CLEAR_EXCHANGE_STATE,
  SET_CURRENCY_EXCHANGE_PROCESSING_INFO,
  SET_PAYMENT_BANK_INFO,
  SET_DEPOSIT_BANK_INFO,
  SET_EXCHANGE_TRANSFER_PURPOSE,
  SET_CURRENCY_EXCHANGE_MANUAL_PAYMENT_FLOW,
  SET_CURRENCY_EXCHANGE_PROCESSING_MANUAL_FLOW,
  SET_DEPOSIT_DATE
} from './actionTypes';
import { apiMainQuery } from '../../settings/apollo/api/main/query';
import { apiMainMutation } from '../../settings/apollo/api/main/mutation';
import { 
  currencyExchangeQuery, 
  walletBalanceQuery, 
  exchangeRatesOnlineQuery, 
  saveCurrencyExchangeMutation, 
  calculateCurrencyExchangeQuery, 
  currencyExchangePurposesQuery,
  cancelCurrencyExchangeReasonsQuery,
  cancelCurrencyExchangeMutation
} from './graphql.graphql';

export const clearExchangeState = () => ({ type: CLEAR_EXCHANGE_STATE });

export const setOriginalCountryId = (originalCountryId) => ({
  type: SET_ORIGINAL_COUNTRY_ID,
  payload: originalCountryId,
});

export const setConvertedCountryId = (convertedCountryId) => ({
  type: SET_CONVERTED_COUNTRY_ID,
  payload: convertedCountryId,
});

export const setExchangeAmount = (exchangeAmount) => ({
  type: SET_EXCHANGE_AMOUNT,
  payload: exchangeAmount,
});

export const setConvertedAmount = (convertedAmount) => ({
  type: SET_CONVERTED_AMOUNT,
  payload: convertedAmount,
});

export const setExchangeRate = (exchangeRate) => ({
  type: SET_EXCHANGE_RATE,
  payload: exchangeRate,
});

export const setPromoRate = (promoRate) => ({
  type: SET_PROMO_RATE,
  payload: promoRate,
});

export const setSpecialRate = (specialRate) => ({
  type: SET_SPECIAL_RATE,
  payload: specialRate,
});

export const setOriginalCurrencyEftInfoId = (originalCurrencyEftInfoId) => ({
  type: SET_ORIGINAL_CURRENCY_EFT_INFO_ID,
  payload: originalCurrencyEftInfoId,
});

export const setConvertedCurrencyEftInfoId = (convertedCurrencyEftInfoId) => ({
  type: SET_CONVERTED_CURRENCY_EFT_INFO_ID,
  payload: convertedCurrencyEftInfoId,
});

export const exchangeRatesOnline = () => apiMainQuery(exchangeRatesOnlineQuery);

export const walletBalance = async ({ business_unique_id, filters }) => await apiMainQuery(walletBalanceQuery, { business_unique_id, filters });

export const getLatestCurrencyExchange = async cus_id => await apiMainQuery(currencyExchangeQuery, { filters: { cus_id } });

export const saveCurrencyExchange = ({ transaction, payment, business_unique_id }) => {
  if(business_unique_id) {
    return(
    apiMainMutation(saveCurrencyExchangeMutation, { transaction, payment, business_unique_id })
  )}
  else {
    return(
      apiMainMutation(saveCurrencyExchangeMutation, { transaction, payment })
    )
  }
}

export const setOriginalCurrencyFlinksLoginId = (originalCurrencyFlinksLoginId) => ({
  type: SET_ORIGINAL_CURRENCY_FLINKS_LOGIN_ID,
  payload: originalCurrencyFlinksLoginId,
});

export const setConvertedCurrencyFlinksLoginId  = (convertedCurrencyFlinksLoginId) => ({
  type: SET_CONVERTED_CURRENCY_FLINKS_LOGIN_ID,
  payload: convertedCurrencyFlinksLoginId,
});

export const calculateCurrencyExchange = async ({ transfer_amount, country_to_id, receiving_amount, country_from_id, is_promo_rate, promotional_rate, rate_id, is_special_rate }) => await apiMainQuery(calculateCurrencyExchangeQuery, { transfer_amount, country_to_id, receiving_amount, country_from_id, is_promo_rate, promotional_rate, rate_id, is_special_rate });

export const setCurrencyExchangeProcessingInfo = data => ({
  type: SET_CURRENCY_EXCHANGE_PROCESSING_INFO,
  payload: data
});

export const setPaymentBankInfo = data => ({
  type: SET_PAYMENT_BANK_INFO,
  payload: data
});

export const setDepositBankInfo = data => ({
  type: SET_DEPOSIT_BANK_INFO,
  payload: data
});

export const setExchangeDepositDate = data => ({
  type: SET_DEPOSIT_DATE,
  payload: data
});


export const setExchangeTransferPurpose = data => ({
    type: SET_EXCHANGE_TRANSFER_PURPOSE,
    payload: data
});

export const currencyExchangePurposes = (type) => apiMainQuery(currencyExchangePurposesQuery, {
  type
});
 
export const cancelCurrencyExchangeReasons = (ce_id) => apiMainQuery(cancelCurrencyExchangeReasonsQuery, {
  ce_id
});

export const cancelCurrencyExchange = (ce_id, reason) => apiMainMutation(cancelCurrencyExchangeMutation, {
  ce_id,
  reason
})

export const setCurencyExchangeManualFlow = data => ({
  type: SET_CURRENCY_EXCHANGE_PROCESSING_MANUAL_FLOW,
  payload: data
})

export const setCurencyExchangeManualPaymentFlow = data => ({
  type: SET_CURRENCY_EXCHANGE_MANUAL_PAYMENT_FLOW,
  payload: data
})
