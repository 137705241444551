import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { ApolloLink } from '@apollo/client/link/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { store } from '../../../../redux/store';
import { setToken } from '../../../../redux/auth/actions';
import { logout } from '../../../../redux/auth/actions';

const authHeaderLink = setContext(async () => {
    const auth = store ? store.getState().auth : null;
    const { isAuthenticated, publicIdentifier, cp_session_token } = auth || {};
    const { csrfToken } = await setToken();
    const sessionCookie = localStorage.getItem('sessionCookie');

    const headers = {
        'CSRF-Token': csrfToken,
        'APP-VERSION': '1.0.0',
        'DEVICE-TYPE': 'CP',
        Cookie: sessionCookie ? `connect.sid=${sessionCookie}` : '',
    };

    if (cp_session_token) headers['cp_session_token'] = `Bearer ${cp_session_token}`;

    if (!isAuthenticated) headers['Authorization'] = `Bearer ${publicIdentifier}`;

    return ({ headers });
});

const afterwareLink = new ApolloLink((operation, forward) => {
    const { cp_session_token } = store ? store.getState().auth : { cp_session_token: null };
    let context = null;
    let headers = null;
    return forward(operation).map((response) => {
        context = operation.getContext();
        ({ response: { headers } } = context);

        if (headers && headers.get('CP-SESSION-TOKEN') && headers.get('CP-SESSION-TOKEN') !== cp_session_token) {
            store.dispatch(logout(false, false, null));
        }

        return response;
    });
});

const logoutLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
    if (networkError) {
        const statusCode = networkError['statusCode'];
        if ([401, 498, 499, 403].includes(statusCode)) store.dispatch(logout(false, false, null));

        // if token is invalid, retry with public token
        if (statusCode === 498) {
            const { publicIdentifier, isAuthenticated } = store?.getState()?.auth || {};
            const headers = operation.getContext().headers;
            if (!isAuthenticated) headers['Authorization'] = `Bearer ${publicIdentifier}`;

            // Retry the request, by returning the new observable
            return forward(operation);
        }
    }
    if (graphQLErrors && Array.isArray(graphQLErrors)) {
        for (let err of graphQLErrors) {
            // logout for "Unauthorized: Invalid permissions" error
            if (err?.extensions?.code === 401 && store) {
                store.dispatch(logout(false, false, null));
                break;
            }
        }
    }
});

const httpLink = new HttpLink({
    uri: operation => {
        const context = operation.getContext();
        return context.uri;
    }, credentials: 'include'
});

const link = ApolloLink.from([authHeaderLink, afterwareLink, logoutLink, httpLink]);

const isBrowser = typeof window !== 'undefined';

const client = new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser,
    link,
    cache: new InMemoryCache({
        addTypename: false
    }).restore({})
});

export default client;
